<template>
    <div class="page-footer">
        <div class="w1200">
            <div class="links flex jcsb">
                <div class="col col3">
                    <div class="label">Build</div>
                    <div class="row" @click="goto('/build')">SaaS</div>
                    <div class="row" @click="goto('/build')">Public Chains</div>
                    <div class="row" @click="goto('/grow')">Projects</div>
                    <div class="row" @click="goto('/community')">Founders/Creators</div>
                    <div class="row" @click="goto('/build')">Developers</div>
                </div>
                <div class="col col2">
                    <div class="label">Learn</div>
                    <div class="row row2"><a :href="hrefUrl.baseUrl.learn" target="_blank">Wiki</a></div>
                    <div class="row row3"><a :href="hrefUrl.baseUrl.whitepaper" target="_blank">White Paper</a></div>
                </div>
                <div class="col col4">
                    <div class="label">Portfolio</div>
                    <div class="row" @click="goto('/portfolio')">DeFi</div>
                    <div class="row" @click="goto('/portfolio')">NFT</div>
                    <div class="row" @click="goto('/portfolio')">Gamefi</div>
                    <div class="row" @click="goto('/portfolio')">DAO</div>
                </div>
                <div class="col col5">
                    <div class="label">Grow</div>
                    <div class="row" @click="goto('/grow')">Join BIA</div>
                    <div class="row" @click="goto('/grow')">Become a Node</div>
                    <div class="row" @click="goto('/grow')">Grow your Ecosystem</div>
                    <div class="row" @click="goto('/grow')">Defi-Fiat on-ramp</div>
                    <div class="row" @click="goto('/grow')">Fiat-to-NFT solution</div>
                    <div class="row" @click="goto('/grow')">Cooperation Onboarding</div>
                </div>
                <div class="col col6">
                    <div class="label">Community</div>
                    <div class="row"> <a :href="hrefUrl.baseUrl.linktr" target="_blank">DAOs</a></div>
                    <div class="row"> <a :href="hrefUrl.baseUrl.linktr" target="_blank">Ambassador Program</a></div>
                    <div class="row"> <a :href="hrefUrl.baseUrl.bounties">Bounties and Events</a></div>
                </div>
                <div class="col col7">
                    <div class="label">Resources</div>
                    <div class="row" @click="goto('/questionnaire')"> Grants and Funding</div>
                    <div class="row">Brand Toolkits</div>
                    <div class="row" @click="goto('/questionnaire')">Careers</div>
                </div>
            </div>
            <div class="social-media flex aic ">
                <div class="col col1 flex aic">
                    <div class="label">
                        <a href="/#/">
                            <img src="@/assets/imgs/pagefooter/logo.png" alt="">
                        </a>
                    </div>
                    <!--  -->
                    <div class="row row2 flex aic">
                        <a :href="hrefUrl.baseUrl.twitter" target="_blank">
                            <img class="default" src="@/assets/imgs/pagefooter/1.svg" alt="">
                            <img class="ac" src="@/assets/imgs/pagefooter/1_ac.png" alt="">
                        </a>
                        <a :href="hrefUrl.baseUrl.telegram" target="_blank">
                            <img class="default" src="@/assets/imgs/pagefooter/4.svg" alt="">
                            <img class="ac" src="@/assets/imgs/pagefooter/4_ac.svg" alt="">
                        </a>
                        <a :href="hrefUrl.baseUrl.encentive" target="_blank">
                            <img class="default" src="@/assets/imgs/pagefooter/3.svg" alt="">
                            <img class="ac" src="@/assets/imgs/pagefooter/3_ac.svg" alt="">
                        </a>
                        <a :href="hrefUrl.baseUrl.github" target="_blank">
                            <img class="default" src="@/assets/imgs/pagefooter/5.svg" alt="">
                            <img class="ac" src="@/assets/imgs/pagefooter/5_ac.svg" alt="">
                        </a>
                        <!--  <a :href="hrefUrl.baseUrl.docsMedium" target="_blank">
                            <img src="@/assets/imgs/pagefooter/6.svg" alt="">
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PageFooter',
    watch: {

    },
    data() {
        return {
            hrefUrl: this.GLOBAL,
            window: window,
        }
    },
    created() {},
    mounted() {},
    computed: {


    },
    methods: {
        goto(path) {
            window.scrollTo(0, 0);
            this.$router.push(path);
        }

    },
    filters: {}
}
</script>
<style scoped lang="scss">
.page-footer {
    // min-height: 417PX;
    background: #FFFFFF;
    padding-top: 35PX;
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1920PX;
        height: 1PX;
        background: url(../assets/imgs/pagefooter/line.png) no-repeat top center;
        background-size: contain;
    }

    .links {
        height: 337PX;

        .col {
            .label {
                font-family: "Inter-Medium";
                font-size: 18PX;
                line-height: 21PX;
                color: #4E536A;
                margin-bottom: 25PX;

            }

            .row {
                font-family: "Inter-Regular";
                font-size: 14PX;
                line-height: 26PX;
                color: #4E536A;
                opacity: 0.5;
                cursor: pointer;

                &:not(:last-child) {
                    margin-bottom: 15PX;
                }

                a {
                    color: inherit;
                }

                &:hover {
                    color: #356DF3;
                    opacity: 1;
                }
            }
        }
    }

    .social-media {
        height: 80PX;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1920PX;
            height: 1PX;
            background: url(../assets/imgs/pagefooter/line.png) no-repeat top center;
            background-size: contain;
        }

        .col {
            .label {
                margin-right: 90PX;

                a {
                    img {
                        width: 112PX;
                    }
                }
            }

            .row {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 38PX;
                    height: 38PX;
                    border-radius: 7PX;
                    margin-right: 15PX;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: all .3s;

                    }

                    img.default {
                        opacity: 1;
                    }

                    img.ac {
                        opacity: 0;
                        width: 100%;
                    }

                    &:hover {
                        img.default {
                            opacity: 0;
                        }

                        img.ac {
                            opacity: 1;
                        }
                    }

                }
            }
        }

    }

}

@media(max-width:1040px) {

    .page-footer {
        height: auto;
        padding: 0.66667rem .53333rem 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 1PX;
            // background: url(../assets/imgs/pagefooter/line.png) no-repeat top center;
            background: #8F9093;
            background-size: contain;
            opacity: .2;
        }

        .links {
            height: auto;
            flex-wrap: wrap;

            .col {
                width: 50%;
                margin-bottom: .8rem;

                .label {
                    font-family: "Inter-Medium";
                    font-size: .42667rem;
                    line-height: .50667rem;
                    color: #4E536A;
                    margin-bottom: .4rem;

                }

                .row {
                    font-family: "Inter-Regular";
                    font-size: .37333rem;
                    line-height: .45333rem;
                    color: #4E536A;
                    opacity: 0.5;
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-bottom: .26667rem;
                    }

                    a {
                        color: inherit;
                    }

                    &:hover {
                        color: #356DF3;
                        opacity: 1;
                    }
                }

                &.col2 {
                    order: 5;
                }

                &.col3 {
                    order: 1;
                }

                &.col4 {
                    order: 3;
                }

                &.col5 {
                    order: 2;
                }

                &.col6 {
                    order: 5;
                }

                &.col7 {
                    order: 4;
                }
            }
        }

        .social-media {
            height: 2.08rem;
            position: relative;
            margin-top: -.26667rem;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 10rem;
                height: 1PX;
                background: url(../assets/imgs/pagefooter/m/line.png) no-repeat top center;
                background-size: cover;
            }

            .col {
                .label {
                    margin-right: .66667rem;

                    a {
                        img {
                            width: 2rem;
                        }
                    }
                }

                .row {
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 1.01333rem;
                        height: 1.01333rem;
                        border-radius: .18667rem;
                        position: relative;
                        margin-right: 0;

                        &:not(:last-child) {
                            margin-right: .53333rem;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: all .3s;

                        }

                        img.default {
                            opacity: 0;
                        }

                        img.ac {
                            opacity: 1;
                        }

                        // &:hover {
                        //     img.default {
                        //         opacity: 0;
                        //     }

                        //     img.ac {
                        //         opacity: 1;
                        //     }
                        // }

                    }
                }
            }

        }

    }
}
</style>