import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const routes = [{
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "home" */ '../views/Home/Index.vue')
    }, {
        path: '/learn',
        name: 'learn',
        component: () => import( /* webpackChunkName: "learn" */ '../views/Learn/Index.vue')
    }, {
        path: '/build',
        name: 'build',
        component: () => import( /* webpackChunkName: "build" */ '../views/Build/Index.vue')
    }, {
        path: '/grow',
        name: 'grow',
        component: () => import( /* webpackChunkName: "grow" */ '../views/Grow/Index.vue')
    }, {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import( /* webpackChunkName: "portfolio" */ '../views/Portfolio/Index.vue')
    }, {
        path: '/community',
        name: 'community',
        component: () => import( /* webpackChunkName: "community" */ '../views/Community/Index.vue')
    },
    //  {
    //     path: '/resource',
    //     name: 'resource',
    //     component: () => import( /* webpackChunkName: "resource" */ '../views/Resource/Index.vue')
    // }, 
    {
        path: '/prieing',
        name: 'prieing',
        component: () => import( /* webpackChunkName: "prieing" */ '../views/Prieing/Index.vue')
    }, {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import( /* webpackChunkName: "questionnaire" */ '../views/Questionnaire/Index.vue')
    }, {
        path: "*",
        redirect: "/"
    }
]


const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {
            y: 0
        }
    }
})




//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

window.getEncentiveWebsiteVersion = version => {
    if (!localStorage.getItem('encentiveWebsiteVersion') || localStorage.getItem('encentiveWebsiteVersion') !== version) {
        window.localStorage.clear();
        localStorage.setItem('encentiveWebsiteVersion', version);
        window.location.reload(true);
    }
}



router.beforeEach((to, from, next) => {
    const versionScriptDom = document.querySelector('script[src*="encentiveWebsiteVersion.js?v="]');
    versionScriptDom && versionScriptDom.remove();

    const versionScript = document.createElement("script");
    versionScript.src = (location.href.split("#")[0]).split("index.html")[0] + 'encentiveWebsiteVersion.js?v=' + new Date().getTime();
    const s = document.querySelector("script");
    s.parentNode.insertBefore(versionScript, s);

    next();
});


export default router