import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import './assets/css/common.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// import VueAnimateNumber from 'vue-animate-number';
// Vue.use(VueAnimateNumber);

import globalVariable from './common/baseUrl.js'
Vue.prototype.GLOBAL = globalVariable

Vue.prototype.showPcStyle = window.innerWidth > 1040 ? true : false;


// let debounce = function(fn, delay = 100) {
//     if (typeof fn !== "function") {
//         throw new TypeError("Expected a function");
//     }
//     let timer = null;
//     return (...arg) => {
//         if (timer) {
//             clearTimeout(timer);
//         }
//         timer = setTimeout(function() {
//             fn.call(arg);
//         }, delay);
//     };
// };

// window.onresize = debounce(function() {
//     Vue.prototype.showPcStyle = window.innerWidth > 1040 ? true : false;
// })

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')