<template>
    <div class="page-header">
        <div class="w1200 flex aic jcsb">
            <div class="fl flex aic">
                <h1 class="logo">
                    <router-link class="link" :to="{path:'/'}">
                        <img src="@/assets/imgs/pageheader/logo.png" alt="">
                    </router-link>
                </h1>
                <div class="links flex aic" v-if="showPcStyle">
                    <el-popover v-model="popover_ac2" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <router-link slot="reference" class="link" :to="{path:'/build'}" :class="{'ac':popover_ac2}">
                            <span class="text">Build</span>
                            <span class="text ac">Build</span>
                        </router-link>
                        <div class="popover-content">
                            <div class="row" @click="goto('/build')">SaaS</div>
                            <div class="row" @click="goto('/build')">Public Chains</div>
                            <div class="row" @click="goto('/grow')">Projects</div>
                            <div class="row" @click="goto('/community')">Founders/Creators</div>
                            <div class="row" @click="goto('/build')">Developers</div>
                        </div>
                    </el-popover>
                    <el-popover v-model="popover_ac1" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <a slot="reference" :href="hrefUrl.baseUrl.learn" target="_blank" class="link" :class="{'ac':popover_ac1}">
                            <span class="text">Learn</span>
                            <span class="text ac">Learn</span>
                        </a>
                        <div class="popover-content">
                            <div class="row row2"><a :href="hrefUrl.baseUrl.learn" target="_blank">Wiki</a></div>
                            <div class="row row3"><a :href="hrefUrl.baseUrl.whitepaper" target="_blank">White Paper</a></div>
                        </div>
                    </el-popover>
                    <el-popover v-model="popover_ac3" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <router-link slot="reference" class="link" :to="{path:'/grow'}" :class="{'ac':popover_ac3}">
                            <span class="text">Grow</span>
                            <span class="text ac">Grow</span>
                        </router-link>
                        <div class="popover-content">
                            <div class="row" @click="goto('/grow')">Join BIA</div>
                            <div class="row" @click="goto('/grow')">Become a Node</div>
                            <div class="row" @click="goto('/grow')">Grow your Ecosystem</div>
                            <div class="row" @click="goto('/grow')">Defi-Fiat on-ramp</div>
                            <div class="row" @click="goto('/grow')">Fiat-to-NFT solution</div>
                            <div class="row" @click="goto('/grow')">Cooperation Onboarding</div>
                        </div>
                    </el-popover>
                    <el-popover v-model="popover_ac4" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <router-link slot="reference" class="link" :to="{path:'/portfolio'}" :class="{'ac':popover_ac4}">
                            <span class="text">Portfolio</span>
                            <span class="text ac">Portfolio</span>
                        </router-link>
                        <div class="popover-content">
                            <div class="row" @click="goto('/portfolio')">DeFi</div>
                            <div class="row" @click="goto('/portfolio')">NFT</div>
                            <div class="row" @click="goto('/portfolio')">Gamefi</div>
                            <div class="row" @click="goto('/portfolio')">DAO</div>
                        </div>
                    </el-popover>
                    <el-popover v-model="popover_ac5" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <a slot="reference" :href="hrefUrl.baseUrl.linktr" target="_blank" class="link" :class="{'ac':popover_ac5}">
                            <span class="text">Community</span>
                            <span class="text ac">Community</span>
                        </a>
                        <div class="popover-content">
                            <div class="row"> <a :href="hrefUrl.baseUrl.linktr" target="_blank">DAOs</a></div>
                            <div class="row"> <a :href="hrefUrl.baseUrl.linktr" target="_blank">Ambassador Program</a></div>
                            <div class="row"> <a :href="hrefUrl.baseUrl.bounties">Bounties and Events</a></div>
                        </div>
                    </el-popover>
                    <el-popover v-model="popover_ac6" :visible-arrow="false" placement="bottom-start" trigger="hover" class="mr25" popper-class="header-popover">
                        <router-link slot="reference" class="link" :to="{path:'/questionnaire'}" :class="{'ac':popover_ac6}">
                            <span class="text">Resources</span>
                            <span class="text ac">Resources</span>
                        </router-link>
                        <div class="popover-content">
                            <div class="row" @click="goto('/questionnaire')"> Grants and Funding</div>
                            <div class="row">Brand Toolkits</div>
                            <div class="row" @click="goto('/questionnaire')">Careers</div>
                        </div>
                    </el-popover>
                    <!--    <router-link class="link flex aic jcc pricing" :to="{path:'/prieing'}">
                        <span class="text">Pricing</span>
                        <span class="text ac">Pricing</span>
                    </router-link> -->
                </div>
            </div>
            <div class="fr ">
                <div class="links flex aic" v-if="showPcStyle">
                    <router-link class="link flex aic jcc contact-us" :to="{path:'/questionnaire'}">
                        <img src="@/assets/imgs/pageheader/1.svg" alt="">
                        <span>Contact Us</span>
                    </router-link>
                    <a :href="hrefUrl.baseUrl.dapp" target="_blank" class="dapp flex aic jcc"><span>DAPP</span></a>
                </div>
                <img src="@/assets/imgs/pageheader/m/1.svg" alt="" class="img" @click="drawer=true" v-if="!showPcStyle">
            </div>
        </div>
        <el-drawer :append-to-body="true" :visible.sync="drawer" direction="ttb" :show-close="false" :modal="false" size="100%" custom-class="header-drawer" :class="{'showtips':showtips}">
            <div class="title flex aic jcsb" slot="title">
                <img src="@/assets/imgs/pageheader/m/2.svg" alt="" class="logo" @click="drawer=false;$router.push('/')">
                <img src="@/assets/imgs/pageheader/m/3.svg" alt="" class="close" @click="drawer=false">
            </div>
            <div class="links">
                <el-collapse v-model="collapse_active" accordion>
                    <el-collapse-item name="1">
                        <template slot="title">
                            <router-link :to="{path:'/build'}">
                                <span @click="drawer=false" class="text">Build</span>
                            </router-link>
                        </template>
                        <div class="collapse-content">
                            <div class="row" @click="goto('/build')">SaaS</div>
                            <div class="row" @click="goto('/build')">Public Chains</div>
                            <div class="row" @click="goto('/grow')">Projects</div>
                            <div class="row" @click="goto('/community')">Founders/Creators</div>
                            <div class="row" @click="goto('/build')">Developers</div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            <a :href="hrefUrl.baseUrl.learn" target="_blank">
                                <span @click="drawer=false" class="text">Learn</span>
                            </a>
                        </template>
                        <div class="collapse-content">
                            <div class="row row2"><a @click="drawer=false" :href="hrefUrl.baseUrl.learn" target="_blank">Wiki</a></div>
                            <div class="row row3"><a @click="drawer=false" :href="hrefUrl.baseUrl.whitepaper" target="_blank">White Paper</a></div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            <router-link :to="{path:'/grow'}">
                                <span @click="drawer=false" class="text">Grow</span>
                            </router-link>
                        </template>
                        <div class="collapse-content">
                            <div class="row" @click="goto('/grow')">Join BIA</div>
                            <div class="row" @click="goto('/grow')">Become a Node</div>
                            <div class="row" @click="goto('/grow')">Grow your Ecosystem</div>
                            <div class="row" @click="goto('/grow')">Defi-Fiat on-ramp</div>
                            <div class="row" @click="goto('/grow')">Fiat-to-NFT solution</div>
                            <div class="row" @click="goto('/grow')">Cooperation Onboarding</div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            <router-link :to="{path:'/portfolio'}">
                                <span @click="drawer=false" class="text">Portfolio</span>
                            </router-link>
                        </template>
                        <div class="collapse-content">
                            <div class="row" @click="goto('/portfolio')">DeFi</div>
                            <div class="row" @click="goto('/portfolio')">NFT</div>
                            <div class="row" @click="goto('/portfolio')">Gamefi</div>
                            <div class="row" @click="goto('/portfolio')">DAO</div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="5">
                        <template slot="title">
                            <a :href="hrefUrl.baseUrl.linktr" target="_blank">
                                <span @click="drawer=false" class="text">Community</span>
                            </a>
                        </template>
                        <div class="collapse-content">
                            <div class="row"> <a @click="drawer=false" :href="hrefUrl.baseUrl.linktr" target="_blank">DAOs</a></div>
                            <div class="row"> <a @click="drawer=false" :href="hrefUrl.baseUrl.linktr" target="_blank">Ambassador Program</a></div>
                            <div class="row"> <a @click="drawer=false" :href="hrefUrl.baseUrl.bounties">Bounties and Events</a></div>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item name="6">
                        <template slot="title">
                            <router-link :to="{path:'/questionnaire'}">
                                <span @click="drawer=false" class="text">Resources</span>
                            </router-link>
                        </template>
                        <div class="collapse-content">
                            <div class="row" @click="goto('/questionnaire')"> Grants and Funding</div>
                            <div class="row">Brand Toolkits</div>
                            <div class="row" @click="goto('/questionnaire')">Careers</div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <router-link class="link flex aic  contact-us" :to="{path:'/questionnaire'}">
                    <span @click="drawer=false">Contact Us</span>
                </router-link>
            </div>
            <a @click="drawer=false" :href="hrefUrl.baseUrl.dapp" target="_blank" class="dapp flex aic jcc"><span>DAPP</span></a>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name: 'PageHeader',
    props: ['showtips'],
    watch: {

    },
    data() {
        return {
            hrefUrl: this.GLOBAL,
            drawer: false,
            popover_ac1: false,
            popover_ac2: false,
            popover_ac3: false,
            popover_ac4: false,
            popover_ac5: false,
            popover_ac6: false,
            collapse_active: 0,
        }
    },
    created() {},
    mounted() {

    },
    computed: {


    },
    methods: {
        goto(path) {
            this.drawer = false;
            window.scrollTo(0, 0);
            this.$router.push(path);
        }
    },
    filters: {}
}
</script>
<style scoped lang="scss">
.page-header {
    width: 100%;
    height: 80PX;
    left: 0PX;
    top: 0PX;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(12.5PX);
    position: relative;
    z-index: 9;

    .fl {
        .logo {
            margin-right: 82PX;

            .link {
                img {
                    height: 30px;
                }
            }
        }

        .links {
            .mr25 {
                margin-right: 25px;

                &:hover {}
            }

            .link {
                position: relative;
                line-height: 21PX;
                display: flex;

                &:not(:last-child) {
                    margin-right: 28PX;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    height: 14px;
                    left: 0;

                }


                &:after {
                    content: "";
                    opacity: 0;
                    position: absolute;
                    height: 4PX;
                    left: 50%;
                    right: 50%;
                    bottom: -13PX;
                    background: linear-gradient(51.55deg, #356DF3 0%, #7636FF 100%);
                    transition: all .3s;

                }

                .text {
                    font-family: "Inter-Regular";
                    font-size: 17PX;
                    line-height: 21PX;
                    color: #1F2226;
                    opacity: 1;
                    transition: all .3s;

                    &.ac {
                        // font-family: "Inter-Bold";
                        color: #356DF3;
                        position: absolute;
                        left: 0;
                        opacity: 0;
                    }
                }

                &:hover {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 100%;
                        width: 100%;
                        height: 34px;
                        left: 0;

                    }
                }

                &:hover,
                &.router-link-active,
                &.ac {
                    .text {
                        opacity: 0;

                        &.ac {
                            opacity: 1;
                        }
                    }

                    &:after {
                        opacity: 1;
                        left: -3PX;
                        right: -3PX;
                    }

                }
            }

        }
    }

    .fr {
        .links {
            .link {
                margin-right: 15PX;
            }

            .contact-us {
                transition: all .3s;
                width: 128px;
                height: 35px;
                background: rgba(111, 108, 255, 0.1);
                border-radius: 6px;

                img {
                    width: 18PX;
                    margin-right: 9PX;
                }

                span {
                    font-family: 'Inter-Medium';
                    font-size: 14PX;
                    line-height: 17PX;
                    color: #4E536A;
                }

                &:hover {
                    background: rgba(111, 108, 255, 0.25);

                }
            }



            .dapp {
                font-family: 'Inter-Medium';
                font-size: 14PX;
                line-height: 17PX;
                color: #FFFFFF;
                width: 128px;
                height: 35px;
                border-radius: 6px;
                overflow: hidden;
                position: relative;


                span {
                    position: relative;
                    z-index: 1;
                }



                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: all .25s linear;

                }


                &:before {
                    background: linear-gradient(51.55deg, #356DF3 0%, #7636FF 100%);
                    opacity: 1;

                }

                &:after {
                    opacity: 0;
                    background: linear-gradient(0deg, rgba(52, 157, 255, 0.35), rgba(52, 157, 255, 0.35)), linear-gradient(51.55deg, #356DF3 0%, #7636FF 100%);
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        opacity: 1;
                    }
                }





            }
        }
    }
}

@media(max-width:1040px) {
    .page-header {
        padding: 0 .66667rem 0 .66667rem;
        height: 1.2rem;

        .fl {
            .logo {
                .link {
                    img {
                        height: .50667rem;
                        width: auto;
                    }
                }
            }

            .links {
                .link {

                    &:hover,
                    &.router-link-active {}
                }

            }
        }

        .fr {
            .img {
                width: .61333rem;
                height: .50667rem;

            }

        }
    }



}
</style>
<style lang="scss">
@media(max-width:1040px) {
    .el-drawer__wrapper {
        transition: all .3s;

        &.showtips {
            top: 1.2rem;
        }

        .el-drawer__container {
            .el-drawer {
                &.header-drawer {
                    .el-drawer__header {
                        height: 1.2rem;
                        margin: 0;
                        padding: 0 .8rem 0 .66667rem;

                        .title {
                            .logo {
                                height: .53333rem;
                                width: auto;
                            }

                            .close {
                                width: .48rem;
                                height: auto;
                            }
                        }
                    }

                    .el-drawer__body {
                        padding: 0 .66667rem .93333rem;
                        position: relative;

                        .links {
                            .el-collapse {
                                border-top: 0;

                                .el-collapse-item {

                                    .el-collapse-item__header {
                                        height: 1.44rem;

                                        a {
                                            span {
                                                font-family: "Inter-Regular";
                                                font-size: .53333rem;
                                                line-height: .64rem;
                                                color: #1F2226;
                                            }
                                        }

                                        .el-icon-arrow-right {
                                            width: .32rem;
                                            height: .18667rem;
                                            background: url(../assets/imgs/pageheader/m/5.svg) no-repeat top center;
                                            background-size: cover;

                                            &:before {
                                                display: none;
                                            }

                                            // font-size: .37333rem;
                                            // color: #cdcdcd;
                                            // font-weight: 700;
                                            // transform: rotate(90deg);
                                        }
                                    }

                                    .el-collapse-item__wrap {
                                        .el-collapse-item__content {
                                            padding-bottom: 0;

                                            .collapse-content {
                                                .row {
                                                    font-family: "Inter-Regular";
                                                    font-size: .45333rem;
                                                    line-height: .56rem;
                                                    /* identical to box height */
                                                    color: #1F2226;
                                                    margin-bottom: .4rem;
                                                    position: relative;
                                                    padding-left: .66667rem;

                                                    a {
                                                        color: inherit;
                                                    }

                                                    &:before {
                                                        content: "";
                                                        width: .13333rem;
                                                        height: .13333rem;
                                                        border-radius: 50%;
                                                        overflow: hidden;
                                                        background: #d9d9d9;
                                                        position: absolute;
                                                        top: 50%;
                                                        left: 0;
                                                        transform: translateY(-50%);
                                                    }

                                                }
                                            }
                                        }
                                    }

                                    &.is-active {
                                        .el-collapse-item__header {
                                            a {
                                                span {
                                                    color: #356DF3;
                                                }
                                            }

                                            .el-icon-arrow-right {
                                                background: url(../assets/imgs/pageheader/m/4.svg) no-repeat top center;
                                                background-size: cover;
                                                transform: rotate(0);
                                            }
                                        }

                                    }
                                }

                                //


                            }

                            .contact-us {
                                padding: .4rem 0;
                                font-family: "Inter-Regular";
                                font-size: .53333rem;
                                line-height: .64rem;

                                color: #1F2226;
                            }
                        }

                        .dapp {
                            // position: absolute;
                            // bottom: .93333rem;
                            // left: 50%;
                            // transform: translateX(-50%);
                            margin-top: .66667rem;
                            width: 8.66667rem;
                            height: 1.33333rem;
                            background: linear-gradient(51.55deg, #356DF3 0%, #7636FF 100%);
                            border-radius: .4rem;
                            font-family: "Inter-Bold";
                            font-size: .53333rem;
                            line-height: .64rem;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}

.el-popover.header-popover {
    background: #fff;
    // border: 1PX solid #E5E5E5;
    // box-shadow: 0PX 10PX 20PX rgba(31, 34, 38, 0.05);
    border: 0;
    box-shadow: none;
    border-radius: 15PX;
    padding: 0;
    margin-left: -18px;
    margin-top: 34px !important;
    min-width: 160px;
    // display: block !important;

    .popover-content {
        padding: 10px 0;

        .row {
            height: 40px;
            line-height: 40px;
            font-family: "Inter-Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            /* identical to box height */
            color: #1F2226;
            display: flex;
            align-items: center;
            padding: 0 20px;
            position: relative;
            cursor: pointer;

            &:not(:last-child) {
                // border-bottom: 1px solid #E5E5E5;
            }

            a {
                color: inherit;
            }

            &:after {
                content: "";
                width: 15px;
                height: 9px;
                margin-left: 14px;
                background: url(../assets/imgs/home/34_1.svg) no-repeat center center;
                background-size: contain;
            }

            &:hover {
                color: #356DF3;
                // background: rgba(143, 144, 147, .1);

                &:after {
                    background: url(../assets/imgs/home/34.svg) no-repeat center center;
                    background-size: cover;
                }
            }



        }
    }

    .popper__arrow {
        display: none !important;
    }
}
</style>