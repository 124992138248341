<template>
    <div id="app" class="app app-page" :class="{'app-page-showtips':showtips}">
        <div class="global-tips flex aic jcc" :class="{'showtips':showtips}">
            <span class="text">Encentive rebrands to SaaSGo.</span>
            <img src="@/assets/imgs/common/close.svg" alt="" class="close" @click="showtips=false">
            <div class="bg">
                <div class="bg-inner">
                    <div class="real-bg1"></div>
                    <div class="real-bg2"></div>
                    <div class="real-bg3"></div>
                </div>
            </div>
        </div>
        <page-header class="page-header" :showtips="showtips"></page-header>
        <div class="page-body">
            <router-view class="router-view" />
        </div>
        <page-footer class="page-footer"></page-footer>
        <div class="global-social-media">
            <a :href="hrefUrl.baseUrl.twitter" target="_blank">
                <img class="default" src="@/assets/imgs/app/1_1.svg" alt="">
            </a>
            <a :href="hrefUrl.baseUrl.github" target="_blank">
                <img class="default" src="@/assets/imgs/app/2_1.svg" alt="">
            </a>
            <a :href="hrefUrl.baseUrl.telegram" target="_blank">
                <img class="default" src="@/assets/imgs/app/3_1.svg" alt="">
            </a>
            <a :href="hrefUrl.baseUrl.encentive" target="_blank">
                <img class="default" src="@/assets/imgs/app/4_1.svg" alt="">
            </a>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
    name: 'App',
    components: {
        PageHeader,
        PageFooter,
    },
    data() {
        return {
            showtips: true,
            hrefUrl: this.GLOBAL,
        }
    },
    computed: {

    },
    created() {

    },
    mounted() {},
    methods: {

    },
    watch: {

    }
}
</script>
<style scoped lang="scss">
.app-page {
    position: relative;
    transition: all .3s;
    padding-top: 0;

    &.app-page-showtips {
        padding-top: 60px;
    }

    .global-tips {
        height: 0PX;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        &.showtips {
            height: 60PX;
        }

        .text {
            font-family: "Inter-Medium";
            font-size: 17PX;
            line-height: 18PX;
            /* identical to box height, or 108% */
            color: #FFFFFF;
            margin-right: 5PX;
            position: relative;
            z-index: 2;
        }

        .close {
            width: 20PX;
            height: 20PX;
            cursor: pointer;
            position: relative;
            z-index: 2;
        }

        .bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            .bg-inner {
                display: flex;
                width: 300%;
                height: 100%;
                position: relative;

                @keyframes scroll {
                    0% {
                        left: -200%;
                    }

                    100% {
                        left: 0%;
                    }
                }

                animation: scroll 16s infinite linear;


                .real-bg1 {
                    height: 100%;
                    width: 33.33%;
                    background: linear-gradient(90deg, #349DFF 0%, #45CCF7 23.96%, #356DF3 48.27%, #E669EF 100%);

                }

                .real-bg2 {
                    width: 33.33%;
                    height: 100%;
                    background: linear-gradient(90deg, #E669EF 0%, #356DF3 23.96%, #45CCF7 48.27%, #349DFF 100%);
                }

                .real-bg3 {
                    width: 33.33%;
                    height: 100%;
                    background: linear-gradient(90deg, #349DFF 0%, #45CCF7 23.96%, #356DF3 48.27%, #E669EF 100%);


                }
            }
        }
    }

    .global-social-media {
        position: fixed;
        z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        right: 151px;
        width: 45px;

        a {
            position: relative;
            width: 45px;
            height: 45px;
            display: block;
            margin-bottom: 15px;

            img {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transition: all .3s;
                width: 45px;
            }

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }

}

@media (max-width: 1500px) {
    .app-page {
        .global-social-media {
            right: 50px;
        }
    }

}


@media (max-width: 1040px) {
    .app-page {
        &.app-page-showtips {
            padding-top: 1.2rem;
        }

        .global-tips {
            &.showtips {
                height: 1.2rem;
            }

            .text {
                font-family: "Inter-Medium";
                font-size: .45333rem;
                line-height: .48rem;
                margin-right: .26667rem;
            }

            .close {
                width: .53333rem;
                height: .53333rem;
            }

            .bg {
                .bg-inner {
                    // animation: scroll 6.25s infinite linear;
                    animation: scroll 5s infinite linear;

                    .real-bg1 {
                        background: linear-gradient(90deg, #349DFF 0%, #356DF3 48.27%, #E669EF 100%);
                    }

                    .real-bg2 {
                        background: linear-gradient(90deg, #E669EF 0%, #356DF3 48.27%, #349DFF 100%);
                    }

                    .real-bg3 {
                        background: linear-gradient(90deg, #349DFF 0%, #356DF3 48.27%, #E669EF 100%);
                    }
                }
            }
        }

        .global-social-media {
            display: none;
        }

    }



}
</style>